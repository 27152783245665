<template>
  <div class="padding-sm" >
    <el-descriptions title="课程详情" direction="vertical" :column="3" border size="mini" v-loading="loading">
      <el-descriptions-item label="上课老师">{{info.tname}}</el-descriptions-item>
      <el-descriptions-item label="上课校区">{{info.school}}</el-descriptions-item>
      <el-descriptions-item label="课程名称" >{{info.cname}}</el-descriptions-item>
      <el-descriptions-item label="上课时间" >{{info.start_at}} - {{info.end_at}}</el-descriptions-item>
      <el-descriptions-item label="课程时长">{{info.time}}分钟</el-descriptions-item>
      <el-descriptions-item label="课节">
        <el-tag size="small">第{{info.section}}节</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="助教老师" :span="3">
        <el-tag v-for="(item,index) in info.helpers_name" class="margin-r-sm" type="warning" :key="index">{{item}}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="备注" :span="3">{{info.remark}}</el-descriptions-item>
    </el-descriptions>

    <div class="margin-t-sm">
      <div style="font-size: 16px;font-weight: bold">课堂情况</div>
     <div style="display: flex;justify-content: space-between;align-items: center;margin-top: 10px">
       <div>
         <el-checkbox v-model="isSectionLog" @change="changeSectionLog">只看本节课记录</el-checkbox>
       </div>
       <div>
<!--         <el-button size="mini" @click="studentDialogVisible = true">添加学生</el-button>-->
<!--         <el-button type="primary" size="mini" @click="dialogVisible = true;getStudent()">添加记录</el-button>-->
       </div>
     </div>
      <el-table
          class="margin-t-sm"
          :data="log"
          v-loading="tabLoading"
          border
          height="400"
          stripe
          style="width: 100%">
        <el-table-column
            prop="id"
            label="ID">
        </el-table-column>
        <el-table-column
            prop="sname"
            label="学生姓名">
        </el-table-column>
        <el-table-column
            prop="sname"
            label="课节">
          <template slot-scope="scope">
            <el-tag>第{{scope.row.section}}节</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="practice"
            label="课堂练习">
        </el-table-column>
        <el-table-column
            prop="expression"
            label="课堂表现">
        </el-table-column>
        <el-table-column
            prop="contentd"
            label="课堂内容">
        </el-table-column>
        <el-table-column
            prop="remark"
            label="备注">
        </el-table-column>
        <el-table-column
            prop="score"
            label="课堂评分" width="155">
          <template slot-scope="scope">
            <el-rate
                v-model="scope.row.score"
                disabled
                text-color="#ff9900"
                score-template="{value}"
                show-score
                show-text>
            </el-rate>
          </template>
        </el-table-column>
        <el-table-column
            prop="tags"
            label="标签">
          <template  slot-scope="scope">
<!--            {{scope.row.tags}}-->
            <div v-for="(tag,index) in scope.row.tags" :key="index">
              <el-tag class="margin-r-sm margin-b-sm" size="mini">{{tag}}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="tname"
            label="记录人">
        </el-table-column>
        <el-table-column
            prop="created_at"
            label="创建时间">
        </el-table-column>
        <el-table-column
            prop="address"
            label="操作" width="150">
          <template  slot-scope="scope">
            <el-button size="mini" type="primary" @click="editLog(scope.row)">编辑</el-button>
            <el-popconfirm class="margin-l-sm" title="删除后不可恢复，确定删除吗？" @confirm="delLog(scope)" >
              <el-button slot="reference" size="mini" type="danger">删除</el-button>
            </el-popconfirm>

          </template>
        </el-table-column>
      </el-table>

      <div style="display: flex;justify-content: end;margin-top: 10px;align-items: center">
        <el-pagination
            small
            background
            @prev-click="changePage"
            @next-click="changePage"
            @current-change="changePage"
            @size-change="changePageSize"
            layout="total,sizes,prev, pager, next"
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            :total="total">
        </el-pagination>
      </div>

    </div>

    <el-dialog
        title="添加记录"
        append-to-body
        :destroy-on-close="true"
        :close-on-click-modal="false"
        :show-close="false"
        :visible.sync="dialogVisible"
        width="500px">
      <div>
        <el-form ref="form" :model="form" label-width="80px" size="mini">
          <el-form-item label="学生姓名">
<!--            <el-input v-model="form.name" placeholder="选择学生"></el-input>-->
            <el-select v-model="form.student_id" placeholder="请选择" style="width: 100%">
              <el-option
                  v-for="item in students"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课堂内容">
            <el-input v-model="form.contentd"  type="textarea" rows="2" show-word-limit maxlength="400"></el-input>
          </el-form-item>
          <el-form-item label="课堂表现">
            <el-input v-model="form.expression"  type="textarea" rows="2" show-word-limit maxlength="400"></el-input>
          </el-form-item>
          <el-form-item label="课堂练习">
            <el-input v-model="form.practice"  type="textarea" rows="2" show-word-limit maxlength="400"></el-input>
          </el-form-item>
          <el-form-item label="课堂评分" >
              <el-rate style="display: flex;justify-content: flex-start;align-items: center"
                  v-model="form.score"
                  show-text>
              </el-rate>
          </el-form-item>
          <el-form-item label="课堂标签">
            <el-checkbox-group v-model="form.tags" size="mini">
              <el-checkbox-button :label="tag" v-for="(tag,index) in tags" :key="index"></el-checkbox-button>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="其他标签">
            <div  class="margin-b-sm"
                  style="display:
                  flex;justify-content: space-between;align-items: center"
                  v-for="(item,index) in form.tags"
                  :key="index"
                  v-show="tags.indexOf(item) === -1"
            >
              <el-input style="width: 80%" v-model="form.tags[index]"  placeholder="请输入自定义标签"/>
              <el-button class="margin-l-sm" size="mini" type="danger" @click="delTags(index)">-</el-button>
            </div>
            <el-button size="mini" @click="addTags">+</el-button>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="form.remark" type="textarea" rows="2" show-word-limit maxlength="400"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
    <el-button type="primary" @click="setLog" size="mini" :loading="setLoading">确 定</el-button>
  </span>
    </el-dialog>


    <el-dialog
        title="添加学生"
        append-to-body
        :destroy-on-close="true"
        :close-on-click-modal="false"
        :show-close="false"
        :visible.sync="studentDialogVisible"
        width="500px">
      <div>
        <el-form ref="form" :model="studentForm" label-width="80px" size="mini">
          <el-form-item label="学生姓名">
            <el-input v-model="studentForm.name" placeholder="输入学生姓名"></el-input>
          </el-form-item>
          <el-form-item label="课程班级">
            <el-input v-model="info.cname" disabled placeholder="选择学生"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="studentDialogVisible = false" size="mini">取 消</el-button>
    <el-button type="primary" @click="setStudent" size="mini" :loading="setStudentLoading">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
  import {mapActions} from "vuex";

  export default {
    data(){
      return {
        isSectionLog:true,
        table:[],
        info:{},
        log:[],
        tags:[
            '古文阅读',
            '现代文阅读',
            '名著阅读',
            '看图写话',
            '写作练笔',
            '复习',
        ],
        total:0,
        page:1,
        pageSize:10,
        students:[],
        loading:false,
        tabLoading:true,
        setLoading:false,
        setStudentLoading:false,
        dialogVisible:false,
        studentForm:{
          name:'',
          course_id:''
        },
        studentDialogVisible:false,
        form:{
          id:'',
          student_id:'',
          remark:'',
          practice:'',
          expression:'',
          contentd:'',
          score:'',
          tags:[],
          scheduling_id:'',
          course_id:'',
        }
      }
    },
    methods:{
      ...mapActions("courseScheduling", [
          "getSchedulingDetail",
          'setSchedulingLog',
          'courseSchedulingNotify',
          'setSchedulingStudent',
          'getSchedulingStudent',
          'getSchedulingLog',
      ]),
      ...mapActions("common", ["delete"]),
      async getDetail(id){
        this.loading = true
        let res = await this.getSchedulingDetail(id)
        this.info = res.data
        this.loading = false
      },
      setLog(){
        console.log(this.form)
        this.setLoading=true
        this.form.course_id = this.info.course_id
        this.setSchedulingLog(this.form).then(res => {
          if(res.ret == 0){
            this.$message.success('操作成功')
            this.dialogVisible = false
            this.form.student_id = ''
            this.form.id = ''
            this.form.remark = ''
            this.form.practice = ''
            this.form.expression = ''
            this.form.contentd = ''
            this.form.score = ''
            this.form.tags = []
            this.getLog()
          }
          console.log(res)
        }).finally(()=>{
          this.setLoading = false
        })
      },
      setStudent(){
        this.setStudentLoading=true
        this.studentForm.course_id = this.info.course_id
        this.setSchedulingStudent(this.studentForm).then(res => {
          if(res.ret ==0){
            this.$message.success('添加成功')
            this.studentForm.name = ''
            this.studentDialogVisible = false
          }
        }).finally(()=>{
          this.setStudentLoading=false
        })
        console.log(this.studentForm)
      },
      delLog(scope){
        let index = scope.$index
        this.delete({id:scope.row.id,model:'CourseScheduling\\CourseSchedulingLog'}).then(res => {
          if(res.ret == 0){
            this.log.splice(index,1)
            this.$message.success('删除成功！')
          }
        })
      },
      delScheduling(){
        this.delete({id:this.$route.params.id,model:'CourseScheduling\\CourseScheduling'}).then(res => {
          if(res.ret == 0){
            this.$message.success('删除成功！')
            this.$router.push('/courseScheduling')
          }
        })
      },
      editLog(row){
        this.getStudent()
        this.dialogVisible = true;
        this.form.id = row.id
        this.form.name = row.name
        this.form.remark = row.remark
        this.form.practice = row.practice
        this.form.expression = row.expression
        this.form.contentd = row.contentd
        this.form.score = row.score
        this.form.tags = eval(row.tags)
        this.form.scheduling_id = row.scheduling_id
        this.form.student_id = row.student_id
        console.log(row)
      },
      notify(){
        this.courseSchedulingNotify(this.$route.params.id).then(res => {
          if(res.ret == 0){
            this.$message.success('添加成功，开始上课前30分钟前会发公众提醒您')
          }else{
            this.$message.error('订阅消息失败');
          }
        })
      },
      getStudent(){
        this.getSchedulingStudent({
          course_id:this.info.course_id
        }).then(res => {
          this.students = res.data
          console.log(res)
        })
      },
      getLog(){
        this.tabLoading = true
        let logId = this.isSectionLog ? this.$route.params.id : ''
        this.getSchedulingLog({
          course_id:this.info.course_id,
          scheduling_id:logId,
          page:this.page,
          pageSize:this.pageSize
        }).then(res =>{
          console.log(res)
          this.log = res.data.list
          this.total = res.data.total
        }).finally(()=>{
          this.tabLoading = false
        })
      },
      changePage(page){
        this.page=page
        this.getLog()
      },
      changePageSize(e){
        this.pageSize = e
        this.getLog()
      },
      changeSectionLog(){
        this.getLog()
      },
      addTags(){
        this.form.tags.push('')
      },
      delTags(index){
        this.form.tags.splice(index,1)
      }

    },
    async mounted() {
      this.form.scheduling_id = this.$route.params.id
      await this.getDetail(this.$route.params.id)
      this.getLog()
    }
  }
</script>



<style scoped>

</style>